import { DocumentSnapshot } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useFetchKnocks from "../../../hooks/useFetchKnocks";
import { Knock, KnockStatus } from "../../../types";
import { Link, useLocation } from "react-router-dom";
import { routeNames } from "../../../routes";
import useFetchCategories from "../../../hooks/useFetchCategories";
import { KnocksTablePagination } from "./KnocksTablePagination";
import DateTimeDisplay from "../../../ui/dateTimeDisplay";

type KnocksTableProps = { status?: KnockStatus; };

const ITEMS_PER_PAGE = 100;

export const KnocksTable = ({ status }: KnocksTableProps) => {
  const location = useLocation();
  const [page, setPage] = useState(0);

  const categoriesFetch = useFetchCategories();

  const categories = useMemo(() => {
    return categoriesFetch.data?.docs.map((doc) => doc.data()) ?? [];
  }, [categoriesFetch.data]);

  const cursors = useRef<Map<number, DocumentSnapshot>>(new Map());

  const knocksFetch = useFetchKnocks({
    status,
    params: {
      cursor: cursors.current.get(page),
      itemsPerPage: ITEMS_PER_PAGE,
    },
  });

  useEffect(() => {
    setPage(0);
  }, [status]);

  const knocks = useMemo(() => {
    return knocksFetch.data?.docs.map((doc) => doc.data()) ?? [];
  }, [knocksFetch.data]);

  // callback called when changing page
  const onPageChanged = useCallback(
    (nextPage: number) => {
      setPage((page) => {
        // first, we save the last document as page's cursor
        cursors.current.set(
          page + 1,
          knocksFetch.data.docs[knocksFetch.data.docs.length - 1]
        );

        // then we update the state with the next page's number
        return nextPage;
      });
    },
    [knocksFetch.data]
  );

  const statusBadgeClass = (knock: Knock) => {
    switch(knock.status) {
      case "searching":
        return "badge-info";
      case "active":
        return "badge-success";
      case "expired":
        return "badge-error";
      default:
        return "";
    }
  }


  const showStatusColumn = status === undefined;
  const groupedColumnCount = showStatusColumn ? 6 : 5;

  return (
    <div className="overflow-x-auto">
      <table className="table w-full">
        <thead>
          <tr>
            <th>Customer name</th>
            <th className="hidden lg:table-cell">Category</th>
            {showStatusColumn ? (
              <th className="hidden lg:table-cell">Status</th>
            ) : null}
            <th className="hidden lg:table-cell">Accepted</th>
            <th className="hidden lg:table-cell">Created at</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {knocksFetch.status === "loading" ||
          categoriesFetch.status === "loading" ? (
            <tr>
              <td colSpan={groupedColumnCount}>Loading...</td>
            </tr>
          ) : knocks.length > 0 ? (
            knocks.map((knock: Knock) => {
              const category = categories.find(
                (item) => item.id === knock.categoryId
              );

              
              let acceptedBadgeClass = knock.chatsCount > 0 ? "badge-success" : "badge-error";

              const statusBadge = (
                <span className={`badge ${statusBadgeClass(knock)}`}>
                  {knock.status}
                </span>
              );

              const acceptedBadge = (
                <span className={`badge ${acceptedBadgeClass}`}>
                  {knock.chatsCount}
                </span>
              );

              return (
                <tr key={knock.id}>
                  <th className="font-normal flex flex-col lg:table-cell">
                    <div>{knock.customerName}</div>
                    <div className="lg:hidden text-sm">
                      <div>{category?.name}</div>
                      <div className="mt-2">Status: {statusBadge}</div>
                      <div className="mt-2">Accepted: {acceptedBadge}</div>
                      <div>
                        <DateTimeDisplay date={knock.createdAt} />
                      </div>
                    </div>
                  </th>
                  <td className="hidden lg:table-cell">{category?.name}</td>
                  {showStatusColumn ? (
                    <td className="hidden lg:table-cell">{statusBadge}</td>
                  ) : null}
                  <td className="hidden lg:table-cell">{acceptedBadge}</td>
                  <td className="hidden lg:table-cell">
                      <DateTimeDisplay date={knock.createdAt} />
                  </td>
                  <td>
                    <Link
                      to={{
                        pathname: routeNames["knocks.show"](knock.id),
                        search: `?referrer=${encodeURIComponent(
                          location.pathname
                        )}`,
                      }}
                    >
                      <button className="btn btn-primary btn-sm mr-2">
                        Details
                      </button>
                    </Link>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={groupedColumnCount} className="italic">
                There are no requests
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={groupedColumnCount}>
              <KnocksTablePagination
                currentPage={page}
                itemsPerPage={ITEMS_PER_PAGE}
                status={status}
                onPageChange={onPageChanged}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
